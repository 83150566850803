.endpage {
  height: 100vh;
}

.endpage-conatiner {
  height: 94vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.rsvp-buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
