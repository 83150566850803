.YtVideo-conatiner {
  width: 98%;
  height: 30vh;
}
.YtVideo {
  width: 100%;
  height: 100%;
}

.image-container {
  height: 100%;
  width: 100%;
}
