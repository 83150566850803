.crossword-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: aliceblue;
}

.crossword .direction {
  margin-top: -22px;
}

.crossword .grid {
  /* border: 2px solid red; */
}

.clues {
  font-weight: bold;
  font-size: 0.7rem;
  margin-left: -5vw;
  width: 90vw;
  border-radius: 10px;
  margin-top: -20px;
}

.clue {
  color: wheat;
  padding: 0;
  margin: auto;
  font-size: 0.75rem;
}

.crossword h3 {
  color: gold;
}
.crossword h3:nth-child(1) {
  padding-top: 10px;
}

.crossword-top-image {
  width: 90%;
  height: 14vh;
}

.crossword-top-image image {
  width: 100%;
}
