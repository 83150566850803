.quiz-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: fixed no-repeat;
  background-size: cover;
  height: 100vh;
}
.top {
  display: flex;
  height: 70vw;
  width: 90vw;
  position: relative;
  z-index: 1;
}
.bg-image {
  width: 110%;
  height: 85vw;
  margin-left: -4vw;
}
.picture-image {
  height: 100%;
  width: 100%;
  position: absolute;
  margin-top: 3.5vh;
  border: 2px solid white;
}
.flip {
  transform: rotateY(180deg);
}

.overlay-top {
  position: absolute;
  height: 34%;
  width: 50.5%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: transform 1.5s;
  margin-top: 3.3vh;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: aliceblue !important; */
}
.overlay-top:nth-child(2) {
  top: 33%;
}
.overlay-top:nth-child(3) {
  top: 33%;
  left: 49%;
}
.overlay-top:nth-child(4) {
  top: 66%;
}

.overlay-top:nth-child(6) {
  top: 66%;
  left: 49%;
}
.overlay-top:nth-child(7) {
  top: 0;
  left: 49%;
}
.question-container {
  margin-top: 8vh;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.question-container > div {
  padding: 1.5rem;
}

.question {
  font-size: 0.9rem;
  font-weight: bold;
}

.answers {
  padding: 10px;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 500;
  padding-left: 10px;
  text-transform: uppercase;
}

.answer-a {
  margin-top: 5px;
  transition: 0.5s;
}
.answer-b {
  transition: 0.5s;
}
.answer-c {
  transition: 0.5s;
}
.answer-d {
  transition: 0.5s;
}

.correct-answer {
  background-color: green;
  border-radius: 10px;
}
.wrong-answer {
  background-color: red;
  border-radius: 10px;
}
