.main-image-puzzle-container {
  background: fixed no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.image-puzzle-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.puzzle-container {
  display: flex;
  padding: 10px;
  background-size: cover;
}

.puzzle-container > div {
  padding: 2px !important;
}

.bottom-buttons {
  width: 100%;
  padding: 20px;
  border: 2px solid black;
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.upload-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  height: 70vh;
}
