.backgroundImage {
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.topImage {
  /* border: 2px solid black; */
}
.topImage > img {
  width: 100%;
  height: auto;
}

.clueImageContainer {
  height: 40vh;
  margin: 5vh 1vw 0 1vw;
  text-align: center;
  background-repeat: repeat;
  background-size: cover;
}

.clueImage {
  height: auto;
  height: 40vh;
}
.clueImage > img {
  width: 100%;
  height: 100%;
}
.clueVideo {
  height: 40vh;
}
.ytVideo {
  height: 100%;
  width: 100%;
}

.clueInput {
  margin-top: 3vh;
  margin-bottom: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clueInput > input {
  width: 70%;
  outline: none;
  border: 3px solid gold;
  padding: 5px;
  border-radius: 10px;
  font-weight: bold;
  margin: 10px 0;
  letter-spacing: 3px;
}
.clueInput > button {
  margin: 10px 0;
  width: 40%;
  border-radius: 10px;
  height: 5vh;
  outline: none;
  border: 3px solid green;
  font-weight: bold;
  letter-spacing: 3px;
}
