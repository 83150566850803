.upload-container {
  background: fixed no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.upload-location {
  display: flex;
  flex-direction: column;
  margin: 20px;
}
.upload-location > span {
  font-size: 1rem;
  font-weight: bold;
}
