.home-container {
  background: fixed no-repeat;
  height: 100vh;
}

.home {
  height: 90vh;
}

.heading-image {
  width: 100%;
  height: 70vh;
}

.heading-image > img {
  width: 100%;
  height: 100%;
}

.home-input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  height: 20vh;
}

.home-input-container > button {
  width: 30%;
  border-radius: 10px;
}
