.clue-game-main-container {
  background: fixed no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.hint-image {
  width: 100vw;
  height: auto;
  padding: 10px;
}

.hint-image > img {
  width: 100%;
  height: 100%;
}

.cluegame {
  width: 100%;
  max-width: 640px;
  height: 15vh;
  padding: 10px;
  display: flex;
  perspective: 1000px;
  overflow: hidden;
}

.cluecard {
  width: calc(32% - 10px);
  height: 100%;
  position: relative;
  margin: 7px;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}
.active {
  transform: scale(0.97);
  transition: transform 0.2s;
}
.flip {
  transform: rotateY(180deg);
}

.clue-image-front,
.clue-image-back {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  backface-visibility: hidden;
}

.clue-image-front {
  transform: rotateY(180deg);
}
.input-container {
  padding-left: 20px;
  padding-right: 20px;
}
.btn {
  display: flex;
  justify-content: space-around;
  margin-top: 2vh;
}
.btn > button {
  width: 40vw;
  border-radius: 7px;
}
